import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/past-events.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village</title>
    </PageHead>
  );
}

function PastEventsPage() {
  const Catalunathon23Flyer = '../images/events/23.04.15-catalunathon/catalunathon-square-flyer.png';
  return (
    <Page className={styles.PastEvents}>
      <ul className={styles.eventsList}>
        <li className={styles.pastEvent}>
          <h3 className={styles.eventDate}>April 15, 2023</h3>
          <Link to="/events/23.04.15-catalunathon">
            <StaticImage
              src={Catalunathon23Flyer}
              alt="Catalunathon Event Flyer '23"
              layout="fullWidth"
              loading="eager"
            />
            <h3 className={styles.galleryLink}>View Event Gallery</h3>
          </Link>
        </li>
      </ul>
    </Page>
  );
}

export default PastEventsPage;
